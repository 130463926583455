<template>
  <div class="flex pr-24 xl:pr-0 md:flex-col md:items-center">
    <div class="mr-12 flex-shrink-0 w-48 md:mr-0 md:mb-6">
      <Avatar />
    </div>
    <div class="flex-grow w-72">
      <h2 class="text-xl text-primary mb-6 font-bold">填寫你的個人資料吧！</h2>
      <El-Form
        ref="formRef"
        v-loading="loading"
        label-position="top"
        :model="form"
        :rules="rules"
      >
        <El-Form-Item>
          {{ basic.email }}
        </El-Form-Item>
        <El-Form-Item>
          {{ basic.phone }}
        </El-Form-Item>

        <El-Form-Item
          prop="name"
          :rules="{
            required: true,
            message: '未填寫內容',
          }"
          class="relative"
        >
          <El-Input v-model="form.name" placeholder="輸入你要的暱稱">
          </El-Input>
        </El-Form-Item>

        <Gender v-model="form.gender"></Gender>
        <Birth v-model="form.birthday"></Birth>
        <Address v-model="form.address"></Address>
        <Education v-model="form.education"></Education>

        <El-Form-item class="">
          <div class="flex">
            <el-button type="text" round @click="skip">下次再說</el-button>
            <el-button type="primary" round class="flex-grow" @click="onSubmit"
              >確認</el-button
            >
          </div>
        </El-Form-item>
        <div class="text-center text-gray text-sm">
          可以隨時到個人資料裡進行填寫喔！
        </div>
      </El-Form>
    </div>
  </div>
</template>

<script>
import { reactive, ref, defineComponent, inject, h, onMounted } from 'vue';
import { ElFormItem, ElForm, ElInput } from 'element-plus';

import Avatar from '@/components/form/Avatar.vue';
import Gender from '@/components/form/Gender.vue';
import Education from '@/components/form/Education.vue';
import Address from '@/components/form/Address.vue';
import Birth from '@/components/form/Birth.vue';
import { useRouter } from 'vue-router';
import useProfile from './useProfile';
import MessageBox from '@/components/messageBox/Index';

export default defineComponent({
  components: {
    ElFormItem,
    ElForm,
    ElInput,
    Avatar,
    Gender,
    Education,
    Birth,
    Address,
  },
  setup() {
    const { user } = inject('auth');

    const basic = reactive({
      email: user.email,
      phone: user.phone,
    });

    const { form, submit, loading } = useProfile();

    const formRef = ref(null);

    const rules = {};
    const router = useRouter();

    const onSubmit = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          submit().then((res) => {
            if (res.code === 1) {
              user.name = form.name;
              router.push({ name: 'Preference' });
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    };
    const skip = () => {
      router.push({ name: 'Preference' });
    };
    const popup = async () => {
      const confirm = await MessageBox.confirm('', '', 'success', {
        showClose: false,
        showCancelButton: false,
        closOnClickModal: false,
        message: h('div', null, [
          h('div', { class: 'text-3xl font-bold mt-2' }, '註冊成功'),
          h('div', { class: 'mt-2 text-lg font-bold' }, '馬上開始免費體驗'),
          h(
            'div',
            { class: 'mt-3 text-left' },
            '歡迎加入JODY！現在您享有「1日24小時」的免費試用體驗，把握時間現在就去刷題。',
          ),
          h(
            'div',
            { class: 'mt-2 text-xs text-left opacity-70' },
            '有效期間自註冊成功起算24小時，詳細日期/時間可前往「會員方案記錄」查詢。',
          ),
        ]),
      });
    };

    onMounted(() => {
      popup();
    });
    return { basic, onSubmit, skip, form, rules, formRef, loading };
  },
});
</script>

<style lang="sass" scoped>
.el-input
  :deep(&.is-disabled)
    .el-input__inner
      background-color: transparent
      border-radius: 0
      cursor: text
      @apply border-b-gray-300 text-gray border-0
      border-bottom-width: 1px
</style>
